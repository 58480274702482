import {directionType} from './types/direction.type';
import {callbackType} from './types/callback.type';

class TouchEvents {
    private element: Document;
    private threshold: number = 150;
    private startingX: number = 0;
    private startingY: number = 0;
    private movementX: number = 0;
    private movementY: number = 0;
    private target: EventTarget;
    private onTop: callbackType;
    private onRight: callbackType;
    private onDown: callbackType;
    private onLeft: callbackType;
    private touchStart: callbackType;
    private touchEnd: callbackType;
    private touchMove: callbackType;

    constructor(element, threshold?: number) {
        this.element = element;
        if (threshold) {
            this.threshold = threshold;
        }
        this.touchStart = this.handleTouchStart.bind(this);
        this.touchEnd = this.handleTouchEnd.bind(this);
        this.touchMove = this.handleTouchMove.bind(this);
    }

    public listen(): void {
        this.element.addEventListener('touchstart', this.touchStart);
        document.addEventListener('touchend', this.touchEnd);
        this.element.addEventListener('touchmove', this.touchMove);
    }

    public destroy(): void {
        this.element.removeEventListener('touchstart', this.touchStart);
        document.removeEventListener('touchend', this.touchEnd);
        this.element.removeEventListener('touchmove', this.touchMove);
    }

    public setDirectionCallback(direction: directionType, callback): this {
        if (typeof callback !== 'function') {
            return;
        }
        switch (direction) {
            case 'up':
                this.onTop = callback;
                break;
            case 'right':
                this.onRight = callback;
                break;
            case 'down':
                this.onDown = callback;
                break;
            case 'left':
                this.onLeft = callback;
                break;
        }
        return this;
    }

    private handleTouchMove(e): void {
        const {clientX, clientY} = e.touches[0];
        this.movementX = this.startingX - clientX;
        this.movementY = this.startingY - clientY;
    }

    private handleTouchStart(e): void {
        const {clientX, clientY} = e.touches[0];
        this.target = e.target;
        this.startingX = clientX;
        this.startingY = clientY;
    }

    private handleTouchEnd(): void {
        switch (true) {
            case this.movementY > this.threshold && this.movementY >= this.movementX:
                if (typeof this.onTop === 'function') {
                    this.onTop();
                    this.destroy();
                }
                break;
            case this.movementX < -this.threshold && this.movementX <= this.movementY:
                if (typeof this.onRight === 'function') {
                    this.onRight();
                    this.destroy();
                }
                break;
            case this.movementY < -this.threshold && this.movementY <= this.movementX:
                if (typeof this.onDown === 'function') {
                    this.onDown();
                    this.destroy();
                }
                break;
            case this.movementX > this.threshold && this.movementX >= this.movementY:
                if (typeof this.onLeft === 'function') {
                    this.onLeft();
                    this.destroy();
                }
                break;
        }
    }
}

export {TouchEvents};
