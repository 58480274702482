import {AbstractElement} from '../base/src/abstract-element';
import {Dom} from '../base/src/utils/dom';
import {Popup} from '../popup/entry';

import './styles/style.scss';

declare const ajaxRequest: any;
declare const loadFiles: any;
declare const aStorage: any;
declare const $: any;

// TODO: избавится от jQuery плагина bar rating и font awesome

export class ReviewTheme extends AbstractElement {
    private popup: any;

    public submit(e) {
        const form = e.currentTarget;

        ajaxRequest('/review_theme/index/?isAjax=1', $(form).serialize(), () => {
            setTimeout(() => {
                this.popup.close();
            }, 3000);
        }, {
            'waitMessage': 'Отправляем отзыв...',
            'successMessage': 'Спасибо за отзыв!',
            'fadeOutMessage': false,
            'errorMessage': 'Ошибка',
            'statusObj': $(form.querySelector('.status')),
            'buttonObj': $(form.querySelector('.btn')),
            onError: (res) => {
                $(form.querySelector('.btn')).enable();

                if (!res.validation) {
                    Dom.querySelector('#form-review-theme .rating', (element) => {
                        const parent = element.parentNode;

                        if (element.querySelector('select').value == 0) {
                            Dom.addClass(parent, 'shake');

                            setTimeout(() => {
                                Dom.removeClass(parent, 'shake');
                            }, 1000);
                        }
                    });
                }
            }
        });

        e.preventDefault();
    }

    public init() {
        this.popup = new Popup('review-theme');
        this.popup.setContent(this.element.innerHTML);
        this.popup.addEventOpen(() => {
            Dom.querySelector('.rating select', (element) => {
                $(element).barrating({
                    theme: 'fontawesome-stars-o',
                    showSelectedRating: false,
                    allowEmpty: true,
                    emptyValue: '0'
                });

                $(element).barrating('set', 0);
                Dom.fadeIn(element.parentNode.parentNode);
            });
        });

        this.popup.addEventBeforeOpen(() => {
            Dom.prepend(document.querySelector('.tingle-modal__close'),
                document.querySelector('.tingle-modal-box__content'));

            document.querySelector('#form-review-theme')
                .addEventListener('submit', (e) => this.submit(e));
        });

        this.popup.addEventClose(() => {
            aStorage.set('isShowReviewTheme', false);
        });

        this.popup.show(1000);
    }
}

document.addEventListener('DOMContentLoaded', () => {
    Dom.querySelector('#review-theme', (element) => {
        let hasRedirectCustomerAccount = false;

        if (aStorage.get('isShowReviewTheme') === null
            && Dom.hasClass(document.querySelector('body'), 'checkout-onepage-success')) {

            if (document.querySelector('.success_payment_redirect') !== null) {
                hasRedirectCustomerAccount = true;
            }

            aStorage.set('isShowReviewTheme', true);
        }

        if (Dom.isDemoPage() || (aStorage.get('isShowReviewTheme') == true
                && document.referrer.search(/\/\/webvisor\./g) === -1
                && !hasRedirectCustomerAccount)) {

            loadFiles(['/js/lib/fontawesome/css/font-awesome.min.css',
                '//cdn.aristosgroup.ru/libs/jquery-bar-rating/jquery.barrating-1.2.2.min.js',
                '//cdn.aristosgroup.ru/libs/jquery-bar-rating/themes/fontawesome-stars-o.css'], () => {
                const reviwTheme = new ReviewTheme(element);
            });
        }
    });
});
